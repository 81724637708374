/* eslint-disable i18next/no-literal-string */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isLoggedIn, login } from '../utils/AuthService';
import '../index.css';
import { Trans, withTranslation } from 'react-i18next';
import DeviceBetaRhbModeConfig from './DeviceBetaRhbModeConfig';
import { Button } from '@material-ui/core';

const api = require('../utils/yoto-api');

const RHB_CONFIG_DEFAULT = {

    'modes': {
        'day': {
            'content': [

            ]
        },
        'night': {
            'content': [

            ]
        }
    }
};

class DeviceBetaRhbConfig extends Component {

    constructor() {

        super();

        this.state = { deviceConfig: null, updatedConfig: { config: {} },
            message: null,  restartRequired: false,
            openDialog: null, loadingLibrary: false
        };
    }

    async onModeConfigChange(mode, config) {
        const { rhbConfig } = this.state;
        rhbConfig.modes[mode] = config;
        this.setState({ rhbConfig });
        await this.save();
    }

    async save() {
        const { rhbConfig } = this.state;
        try {
            const apiResponse = await api.updateDeviceShortcuts(this.props.params.deviceId, { shortcuts: rhbConfig });
            console.log(apiResponse);
            this.setState({ message: ['', 'Settings updated'] });
            this.savedMessageTimeout = setTimeout(()=> {
                this.setState({ message: null });
            }, 1500);
        } catch (e) {
            this.setState({ message: ['ERROR', 'Error saving settings'] });
            this.savedMessageTimeout = setTimeout(()=> {
                this.setState({ message: null });
            }, 3000);
        }
    }

    async getShortcuts() {
        await this.loadFamilyLibrary();
        let rhbConfig;
        try {
            rhbConfig = await api.getDeviceShortcuts(this.props.params.deviceId);
        } catch (e) {
            rhbConfig = RHB_CONFIG_DEFAULT;
        }
        this.setState({ rhbConfig });
    }

    async resetShortcuts() {
        await api.updateDeviceShortcuts(this.props.params.deviceId, { shortcuts: null });
        await this.getShortcuts();
    }

    async componentDidMount() {

        if (!isLoggedIn()) {
            login(this.props.location.pathname);
        }
        this.loadFamilyLibrary();
        this.getShortcuts();

    }

    async loadFamilyLibrary() {

        this.setState({ loadingLibrary: true });
        this.setState({ loadingMessage: <Trans i18nKey='beta_settings_loading'>Loading Player Beta Settings...</Trans> });
        if (!isLoggedIn()) return;
        try {
            const library = await api.getFamilyLibrary();
            this.setState({
                library,
            });
        } catch (err) {
            this.setState({ loadingMessage: <Trans i18nKey='beta_settings_loading_error'>Sorry, we are unable to load Beta settings for this Yoto Player, please tap the back button and try again</Trans> });
        } finally {
            this.setState({ loadingLibrary: false, loadingMessage: null });
        }

    }

    render() {

        const { library, loadingMessage, rhbConfig } = this.state;
        return (
            <div>
                <div>{this.state.message ?
                    <div className='message' style={{ backgroundColor: this.state.message[0] === 'ERROR' ? '#cc0000' : '#E7F3B9E5' }}>
                        { this.state.message[1] ? <div style={{ fontSize: '0.7em', color: this.state.message[0] === 'ERROR' ? '#ffffff' : '#003C1F', opacity:'1' }}>{this.state.message[1]}</div> : '' }
                    </div> : ''}
                </div>
                {!rhbConfig || !library || loadingMessage ?
                    <div style={{ width: '100%', textAlign: 'center', padding: '2em' }}>{loadingMessage}</div> :
                    <>
                        <h1 style={{ paddingLeft: '20px', fontSize: '24px' }}>Right button shortcuts</h1>
                        <DeviceBetaRhbModeConfig config={rhbConfig.modes['day']} mode="day" library={library} onChange={(config)=>this.onModeConfigChange('day', config)} />
                        <DeviceBetaRhbModeConfig config={rhbConfig.modes['night']} mode="night" library={library} onChange={(config)=>this.onModeConfigChange('night', config)} />
                        <div style={{ padding: '20px', textAlign: 'right'}}>
                            <Button onClick={() => this.resetShortcuts()} color="primary">Reset to Defaults</Button>
                        </div>
                    </>
                }
            </div>
        );
    }
}

DeviceBetaRhbConfig.propTypes = {
    location: PropTypes.object,
    params: PropTypes.object
};

export default  withTranslation()(DeviceBetaRhbConfig);
