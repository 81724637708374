import axios from 'axios';
import { getAccessToken } from './AuthService';
import STAGES from './stages.js';

const BASE_URL = STAGES.API_DOMAINS[STAGES.CLIENT_URLS[window.location.hostname]];
const DEVICE_PREFIXES = STAGES.DEVICE_PREFIXES[STAGES.CLIENT_URLS[window.location.hostname]];

const OFFICIAL_CARD_RESOLVE_DOMAIN = 'yoto.io';
const YOTO_VALID_DOMAINS = [new URL(BASE_URL).hostname, OFFICIAL_CARD_RESOLVE_DOMAIN];

/* eslint-disable i18next/no-literal-string */
const DEVICE_V1 = 'v1';
const DEVICE_V2 = 'v2';
/* eslint-enable i18next/no-literal-string */

function isBetaWebsite() {
    return STAGES.CLIENT_URLS[window.location.hostname] !== 'prod';
}

function getDeviceVersion(deviceId) {
    if (!deviceId) return null;
    if (deviceId.startsWith(DEVICE_PREFIXES.v2) && ([20,22].includes(deviceId.length - DEVICE_PREFIXES.v2.length))) return DEVICE_V2;
    if (deviceId.startsWith(DEVICE_PREFIXES.v1) && (deviceId.length === (22 + DEVICE_PREFIXES.v1.length))) return DEVICE_V1;
    throw new Error(`Unknown device type for deviceId ${deviceId}`);
}

function getDeviceStatus(deviceId) {
    if (getDeviceVersion(deviceId) === DEVICE_V1) {
        return getDeviceStatusV1(deviceId);
    } else {
        return getDeviceStatusV2(deviceId);
    }
}

function getDeviceStatusV1(deviceId) {
    const url = `${BASE_URL}/device/${deviceId}/details`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.device);
}

function getDeviceStatusV2(deviceId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/details`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.device);
}

function getDeviceConfigV1(deviceId) {
    const url = `${BASE_URL}/config/device/${deviceId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.device);
}

function updateDeviceConfigV1(deviceId, deviceConfig) {
    const url = `${BASE_URL}/config/device/${deviceId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.post(url, { deviceId, ...deviceConfig }, config).then(response => response.data.device);
}

function getDeviceConfigV2(deviceId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/config`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.device);
}

function updateDeviceConfigV2(deviceId, deviceConfig) {
    const url = `${BASE_URL}/device-v2/${deviceId}/config`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.put(url, { deviceId, ...deviceConfig }, config).then(response => response.data.device);
}

function updateDeviceReleaseChannel(deviceId, releaseChannelId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/releaseChannel?releaseChannelId=${releaseChannelId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.put(url, { releaseChannelId }, config).then(response => response.data);
}

function sendDeviceCommand(deviceId, command, payload) {
    const url = `${BASE_URL}/device-v2/${deviceId}/command/${command}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.post(url, payload, config).then(response => response.data.data);
}

function getFamilyLibrary(options) {
   const params = { ensureprovenance: false, ...options };
    const url = `${BASE_URL}/card/family/library`;
    const config = { params, headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.cards);
}

function getCardDetails(cardId) {
    const url = `${BASE_URL}/card/details/${cardId}`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.card);
}

function generateAuthorizationHeader(url) {
    const { hostname } = new URL(url);
    //console.log(hostname);
    if (!YOTO_VALID_DOMAINS.includes(hostname)) return undefined;
    const token = getAccessToken();
    return token ? `Bearer ${getAccessToken()}` : undefined;
}

function getDeviceShortcuts(deviceId) {
    const url = `${BASE_URL}/device-v2/${deviceId}/config`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.get(url, config).then(response => response.data.device.shortcuts);
}

async function updateDeviceShortcuts(deviceId, shortcutsConfig) {
    const url = `${BASE_URL}/device-v2/${deviceId}/shortcuts`;
    const config = { headers: {} };
    config.headers.Authorization = generateAuthorizationHeader(url);
    return axios.put(url, shortcutsConfig, config).then(response => response.data);
}



export {
    isBetaWebsite,
    getDeviceVersion,
    getDeviceStatus,
    sendDeviceCommand,
    getDeviceConfigV1,
    getDeviceConfigV2,
    updateDeviceConfigV1,
    updateDeviceConfigV2,
    updateDeviceReleaseChannel,
    getFamilyLibrary,
    getCardDetails,
    getDeviceShortcuts,
    updateDeviceShortcuts
};

